const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: "us-east-2_PrVVmfgqG",
      userPoolClientId: "5pa8g9bn0si9f9ejb98ffvq85r",
      loginWith: {
        email: true,
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      allowGuestAccess: true,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
};



export default awsConfig; 