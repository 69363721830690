/*!

=========================================================
* Now UI Dashboard PRO React - v1.5.2
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import nowLogo from "assets/img/now-logo.png";
import bgImage from "assets/img/bg13.jpg";
import { useNavigate } from 'react-router-dom';
import { signIn } from 'aws-amplify/auth'
import { fetchAuthSession } from 'aws-amplify/auth'
import Intercom from '@intercom/messenger-js-sdk';
import { fetchUserAttributes, confirmSignIn } from 'aws-amplify/auth';
import { getOrgConfig } from '../../utils/api';
import App from '../../App';
import { toast } from 'react-hot-toast';

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";


// const canaryRequest = await getOrgConfig();
// console.log(canaryRequest);


// function signIn() {
//   Amplify.signIn();
// }

function LoginPage() {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [newPasswordRequired, setNewPasswordRequired] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState('');

  function login(event) {
    event.preventDefault();
    
    if (newPasswordRequired && newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match');
      toast.error('Passwords do not match');
      return;
    }
  
    signIn({
      username: email,
      password: password
    })
      .then(async (user) => {
        switch (user.nextStep.signInStep) {
          case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
            setNewPasswordRequired(true);
            toast.info('New password required. Please set a new password.');
            break;
          default:
            toast.success('Login successful!');
            break;
        }
        try {
          // First ensure we have a valid session
          await fetchAuthSession();
          // Then fetch user attributes
          const userAttributes = await fetchUserAttributes();
          // Initialize Intercom with user data
          Intercom({
            app_id: 'hae2ajlm',
            user_id: userAttributes.email,
            name: userAttributes.given_name,
            email: userAttributes.email,
          });
          console.log('Sign in successful:', user);
          navigate('/admin/dashboard');
        } catch (error) {
          console.error('Error fetching user data:', error);
          setPassword('');
          toast.error('Error fetching user data. Please try again.', { duration: 5000 });
        }
      })
      .catch(error => {
        switch (error.name) {
          case 'UserAlreadyAuthenticatedException':
            // Refresh the access token and then navigate
            (async () => {
              try {
                const session = await fetchAuthSession();
                console.log(session);
                toast.success('Session refreshed! Redirecting...');
                navigate('/admin/dashboard');
              } catch (refreshError) {
                console.error('Error refreshing session:', refreshError);
                setPassword('');
                toast.error('Error refreshing session. Please try again.');
              }
            })();
            break;
          default:
            toast.error(`Error signing in: ${error.message}`);
            console.error('Error signing in:', error);
            break;
        }
      });
  }
async function handleNewPasswordSubmit(event) {
  event.preventDefault();

  if (newPassword !== confirmPassword) {
    setPasswordError('Passwords do not match');
    toast.error('Passwords do not match'); // Notify user about mismatched passwords
    return;
  }

  try {
    // Step 1: Sign in with the existing password
    const user = await signIn({
      username: email,
      password: password,
    });

    // Step 2: Check if the user is in the 'NEW_PASSWORD_REQUIRED' state
    if (user.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
      // Step 3: Respond to the challenge by submitting the new password
      await confirmSignIn({
        challengeResponse: newPassword,
      });

      // After successful password change, proceed with the normal login flow
      const session = await fetchAuthSession();
      const userAttributes = await fetchUserAttributes();

      // Initialize Intercom
      Intercom({
        app_id: 'hae2ajlm',
        user_id: userAttributes.email,
        name: userAttributes.given_name,
        email: userAttributes.email,
      });

      // Redirect to the dashboard
      navigate('/admin/dashboard');
      toast.success('Password updated successfully! Redirecting to dashboard...');
    }
  } catch (error) {
    console.error('Error confirming new password:', error);

    setPasswordError('The new password is invalid. Please follow password guidelines.');
    toast.error(error.message);

    }
  
}
  

  const navigate = useNavigate();
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);

  React.useEffect(() => {
    document.body.classList.add("login-page");
    return function cleanup() {
      document.body.classList.remove("login-page");
    };
  }, []);

  return (
    <div className="tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gradient-to-br tw-from-blue-500 tw-to-red-500">
      <div className="tw-backdrop-blur-md tw-bg-white/20 tw-p-8 tw-rounded-lg tw-shadow-lg tw-max-w-sm tw-w-full">
        <h1 className="tw-text-2xl tw-font-bold tw-text-white tw-text-center tw-mb-6">
          {newPasswordRequired ? 'Set New Password' : 'Login'}
        </h1>
        <form onSubmit={newPasswordRequired ? handleNewPasswordSubmit : login}>
          {/* Email Input */}
          <div className="tw-mb-4">
            <label
              htmlFor="email"
              className="tw-block tw-text-sm tw-font-semibold tw-text-white tw-mb-2"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-bg-white/20 tw-text-white tw-placeholder-gray-300 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-purple-300"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {/* Password Input */}
          <div className="tw-mb-6">
            <label
              htmlFor="password"
              className="tw-block tw-text-sm tw-font-semibold tw-text-white tw-mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-bg-white/20 tw-text-white tw-placeholder-gray-300 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-purple-300"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {newPasswordRequired && (
            <>
              <div className="tw-mb-4">
                <label htmlFor="newPassword" className="tw-block tw-text-sm tw-font-semibold tw-text-white tw-mb-2">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-bg-white/20 tw-text-white tw-placeholder-gray-300 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-purple-300"
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </div>
              <div className="tw-mb-6">
                <label htmlFor="confirmPassword" className="tw-block tw-text-sm tw-font-semibold tw-text-white tw-mb-2">
                  Confirm Password
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  className="tw-w-full tw-px-4 tw-py-2 tw-rounded-lg tw-bg-white/20 tw-text-white tw-placeholder-gray-300 tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-purple-300"
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {passwordError && (
                <div className="tw-text-red-300 tw-text-sm tw-mb-4">
                  {passwordError}
                </div>
              )}
            </>
          )}

          <button
            type="submit"
            className="tw-w-full tw-py-2 tw-bg-purple-600 tw-text-white tw-font-bold tw-rounded-lg hover:tw-bg-purple-700 tw-transition tw-duration-300"
          >
            {newPasswordRequired ? 'Set New Password' : 'Log In'}
          </button>
        </form>
        {/* Additional Links */}
        <div className="tw-text-center tw-mt-4">
          <a href="#" className="tw-text-sm tw-text-white hover:tw-underline">
            Forgot your password?
          </a>
        </div>
      </div>
    </div>
  )
}

export default LoginPage;
