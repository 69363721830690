import React from 'react';
import { Amplify } from 'aws-amplify';
import awsconfig from './utils/awsConfig';
import Intercom from '@intercom/messenger-js-sdk';
import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import { Toaster } from 'react-hot-toast'; // Import Toaster


const AUTH = Amplify.configure(awsconfig);
console.log(AUTH);


function App() {
  React.useEffect(() => {
    async function initializeIntercom() {
      try {
        const { tokens } = await fetchAuthSession();
        
        if (tokens) {
          const user = await fetchUserAttributes();
          Intercom({
            app_id: 'hae2ajlm',
            user_id: user.email,
            name: user.given_name,
            email: user.email,
            created_at: 1730166283,
          });
        } else {
          Intercom({
            app_id: 'hae2ajlm',
          });
        }
      } catch (error) {
        console.log(error);
        Intercom({
          app_id: 'hae2ajlm',
        });
        console.log('User is not authenticated or there was an error:', error);
        // Optionally initialize Intercom without user data
        // Intercom({ app_id: 'hae2ajlm' });
      }
    }

    initializeIntercom();
  }, []);


  return (
    <div>
      {/* Add the Toaster component with Glassmorphism styling */}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 5000,
            style: {
              background: 'rgba(255, 255, 255, 0.2)', // Glass-like transparent background
              backdropFilter: 'blur(10px)', // Blur effect for glassmorphism
              border: '1px solid rgba(255, 255, 255, 0.3)', // Subtle border
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // Soft shadow
              color: '#ffffff', // Error red text color
              borderRadius: '12px', // Rounded corners
              padding: '16px', // Padding for spacing
            },
            iconTheme: {
              primary: '#f44336', // Icon color for error
              secondary: 'rgba(255, 255, 255, 0.7)', // Icon background
            },
          },
        }}
      />
    </div>
  );

}


export default App; 